import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import '../../styles/gestion.scss'
import GestionTierras from '../../images/gestion_tierras_nf.png'
import GestionTierrasImg from '../../images/gestion_tierras_main.png'

export default function Gestion({ children, location }) {
	return (
		<Layout currentPath={location}>
			<SEO title="Gestión de Tierras | Proyectos" />
			<div className="gestion">
				<Link to="/#proyectos" className="back">
					&larr;Volver
				</Link>
				<div className="gestion__title">
					<div className="gestion__texture" />
					<h1>GESTIÓN DE TIERRAS</h1>
				</div>
				<div className="gestion__content">
					<div className="gestion__content__leftbar" />
					<img className="gestion__logo" src={GestionTierras} alt="Grupo Ínsula Proyectos Propios" />
					<p>
						Tenemos como principio fundamental la estructuración y optimización de espacios sostenibles a
						través del diseño y la innovación. Creemos firmemente en la funcionalidad que el diseño debe
						tener sobre la arquitectura. Es por eso que nuestros proyectos inmobiliarios siempre están
						pensados para responder a una época de modernidad y conectividad.
					</p>
					<div className="gestion__content__rightbar" />
				</div>
				<img className="gestion__imagen" src={GestionTierrasImg} alt="Gestión de Tierras Grupo Ínsula" />
			</div>
		</Layout>
	)
}
